import React from 'react';
import { ExternalLink, Github } from 'lucide-react';

const PortfolioPage = () => {
  const projects = [
    {
      name: "Code Reprogrammer",
      description: "Code translation tool from any language to Java",
      image: "/gallery/code.svg",
      liveUrl: "https://github.com/izuc/Reprogrammer",
      githubUrl: "https://github.com/izuc/Reprogrammer",
    },
    {
      name: "PHP Slim API CRUD Generator",
      description: "Automates CRUD API creation for PHP Slim",
      image: "/gallery/crud.svg",
      liveUrl: "https://github.com/izuc/crud-php",
      githubUrl: "https://github.com/izuc/crud-php",
    },
    {
      name: "XVEN",
      description: "eXtensible Virtual ENvironment",
      image: "/gallery/xven.svg",
      liveUrl: "https://github.com/izuc/xven",
      githubUrl: "https://github.com/izuc/xven",
    },
    {
      name: "ZIPP.org",
      description: "Zone Internet Peer Protocol",
      image: "/gallery/zipp.gif",
      liveUrl: "https://zipp.org",
      githubUrl: "https://github.com/izuc/zipp.org",
    },
    {
      name: "j0b.ai",
      description: "AI-Powered Job Application Assistant",
      image: "/gallery/j0b_ai.svg",
      liveUrl: "https://github.com/izuc/J0b.ai",
      githubUrl: "https://github.com/izuc/J0b.ai",
    },
    {
      name: "Map.Pics",
      description: "Photo sharing app for Australian campuses",
      image: "/gallery/map_pics.png",
      liveUrl: "https://map.pics",
      githubUrl: "https://github.com/izuc/map.pics",
    },
    {
      name: "Tattled",
      description: "Location based photo social network",
      image: "/gallery/tattled.png",
      liveUrl: "https://tattled.com",
      githubUrl: "#",
    },
    {
      name: "Friend.Software",
      description: "Find gaming friends",
      image: "/gallery/friend_software.png",
      liveUrl: "https://friend.software",
      githubUrl: "#",
    },
    {
      name: "Coffee.Dating",
      description: "Dating website",
      image: "/gallery/coffee_dating.gif",
      liveUrl: "https://coffee.dating",
      githubUrl: "#",
    },
    {
      name: "Website.Live",
      description: "AI-powered website creator",
      image: "/gallery/website_live.png",
      liveUrl: "https://website.live",
      githubUrl: "#",
    },
    {
      name: "Stall.me",
      description: "Marketplace for code and assets",
      image: "/gallery/stall_me.png",
      liveUrl: "https://stall.me",
      githubUrl: "#",
    }
  ];

  return (
    <div>
      <h2 className="text-3xl font-bold mb-6">My Projects</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {projects.map((project, index) => (
          <div key={index} className="bg-white bg-opacity-10 rounded-lg overflow-hidden flex flex-col">
            <div className="relative pt-[100%]"> {/* This creates a square aspect ratio */}
              <img 
                src={project.image} 
                alt={project.name} 
                className="absolute top-0 left-0 w-full h-full object-cover"
              />
            </div>
            <div className="p-4 flex-grow flex flex-col justify-between">
              <div>
                <h3 className="text-xl font-semibold mb-2">{project.name}</h3>
                <p className="text-blue-200 mb-4">{project.description}</p>
              </div>
              <div className="flex justify-between mt-auto">
                <a href={project.liveUrl} target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-300 hover:text-white transition duration-300">
                  <ExternalLink size={20} className="mr-1" /> Live Demo
                </a>
                {project.githubUrl !== "#" && (
                  <a href={project.githubUrl} target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-300 hover:text-white transition duration-300">
                    <Github size={20} className="mr-1" /> GitHub
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PortfolioPage;