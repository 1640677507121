import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, Code, Database, Server, Globe } from 'lucide-react';

const skills = [
  { name: 'Web Development', icon: <Code size={32} />, color: 'bg-blue-500' },
  { name: 'Database Management', icon: <Database size={32} />, color: 'bg-green-500' },
  { name: 'Server Administration', icon: <Server size={32} />, color: 'bg-purple-500' },
];

const featuredProjects = [
  { name: 'ZIPP.org', description: 'Decentralized private internet using IPFS', color: 'bg-indigo-600' },
  { name: 'Code Reprogrammer', description: 'AI-powered code translation tool', color: 'bg-red-600' },
  { name: 'j0b.ai', description: 'AI-Powered Job Application Assistant', color: 'bg-yellow-600' },
];

const domains = [
  { name: 'zipp.org', url: 'https://zipp.org' },
  { name: 'map.pics', url: 'https://map.pics' },
  { name: 'friend.software', url: 'https://friend.software' },
  { name: 'tattled.com', url: 'https://tattled.com' },
  { name: 'bot.computer', url: 'https://bot.computer' },
  { name: 'upload.computer', url: 'https://upload.computer' },
  { name: 'coffee.dating', url: 'https://coffee.dating' },
  { name: 'pit.software', url: 'https://pit.software' },
  { name: 'mine.management', url: 'https://mine.management' },
  { name: 'client.management', url: 'https://client.management' },
];

const HomePage = () => {
  return (
    <div className="space-y-20 text-gray-200">
      {/* Hero Section with Background Video */}
      <section className="relative h-96 overflow-hidden rounded-lg shadow-2xl">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute top-0 left-0 w-full h-full object-cover"
        >
          <source src="home.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center px-4">
          <h1 className="text-4xl md:text-5xl font-bold mb-4 text-white">
            Welcome to My Digital Realm
          </h1>
          <p className="text-xl md:text-2xl text-gray-200 mb-6">
            Crafting elegant solutions through code
          </p>
          <Link
            to="/portfolio"
            className="inline-flex items-center px-6 py-3 text-base font-semibold text-blue-600 bg-white rounded-full hover:bg-gray-100 transition duration-300 shadow-lg"
          >
            Explore My Work
            <ArrowRight size={20} className="ml-2" />
          </Link>
        </div>
      </section>

      {/* Skills Section */}
      <section>
        <h2 className="text-4xl font-bold mb-10 text-center text-white">Core Competencies</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {skills.map((skill, index) => (
            <div
              key={index}
              className={`${skill.color} p-8 rounded-lg shadow-lg hover:shadow-2xl transition duration-300 transform hover:-translate-y-1`}
            >
              <div className="flex items-center justify-center mb-4 text-white">
                {skill.icon}
              </div>
              <h3 className="text-2xl font-semibold text-center text-white">{skill.name}</h3>
            </div>
          ))}
        </div>
      </section>

      {/* Featured Projects Section */}
      <section>
        <h2 className="text-4xl font-bold mb-10 text-center text-white">Featured Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {featuredProjects.map((project, index) => (
            <div
              key={index}
              className={`${project.color} p-8 rounded-lg shadow-lg hover:shadow-2xl transition duration-300 transform hover:-translate-y-1`}
            >
              <h3 className="text-2xl font-semibold mb-4 text-white">{project.name}</h3>
              <p className="text-gray-200">{project.description}</p>
            </div>
          ))}
        </div>
        <div className="text-center mt-12">
          <Link
            to="/portfolio"
            className="inline-flex items-center px-6 py-3 text-lg font-semibold text-white border-2 border-blue-400 rounded-full hover:bg-blue-400 transition duration-300"
          >
            View All Projects
            <ArrowRight size={24} className="ml-2" />
          </Link>
        </div>
      </section>

      {/* Domains Section */}
      <section className="bg-gradient-to-r from-gray-800 to-gray-900 p-6 sm:p-10 rounded-lg shadow-xl">
        <h2 className="text-4xl font-bold mb-8 text-center text-white">My Domains</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          {domains.map((domain, index) => (
            <a
              key={index}
              href={domain.url}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gray-700 p-4 rounded-lg hover:bg-gray-600 transition duration-300 flex items-center"
            >
              <Globe size={24} className="mr-3 text-blue-400 flex-shrink-0" />
              <p className="text-sm font-medium truncate">{domain.name}</p>
            </a>
          ))}
        </div>
      </section>

      {/* About Me Section */}
      <section className="bg-gradient-to-r from-blue-800 to-purple-800 p-10 rounded-lg shadow-2xl">
        <h2 className="text-4xl font-bold mb-6 text-white">About Me</h2>
        <p className="text-xl mb-6 text-gray-200">
          With over a decade of experience in Information Technology, I specialize in creating robust and innovative solutions for complex problems. My expertise spans web development, software engineering, and database management.
        </p>
        <p className="text-xl mb-8 text-gray-200">
          I'm passionate about leveraging cutting-edge technologies to build efficient, scalable, and user-friendly applications that make a real impact.
        </p>
        <Link
          to="/resume"
          className="inline-flex items-center px-6 py-3 text-lg font-semibold text-white bg-blue-600 rounded-full hover:bg-blue-700 transition duration-300 shadow-lg"
        >
          View My Resume
          <ArrowRight size={24} className="ml-2" />
        </Link>
      </section>

      {/* Call to Action */}
      <section className="text-center bg-gradient-to-r from-green-600 to-blue-600 p-10 rounded-lg shadow-2xl">
        <h2 className="text-4xl font-bold mb-6 text-white">Let's Create Something Exceptional</h2>
        <p className="text-2xl text-gray-200 mb-8">
          Have a project in mind? I'd love to hear about it!
        </p>
        <Link
          to="/contact"
          className="inline-flex items-center px-8 py-4 text-lg font-semibold text-green-600 bg-white rounded-full hover:bg-gray-100 transition duration-300 shadow-lg"
        >
          Get in Touch
          <ArrowRight size={24} className="ml-2" />
        </Link>
      </section>
    </div>
  );
};

export default HomePage;