import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Linkedin, Github, Twitter, Home, FileText, Monitor, Mail, Menu, X, MapPin, Phone, Globe, FileCheck } from 'lucide-react';

const profileVideos = [
  'lance.mp4',
];

const socialLinks = [
  { icon: <Linkedin size={24} />, url: 'https://au.linkedin.com/in/lance88', color: 'bg-[#0077B5]' },
  { icon: <Github size={24} />, url: 'https://github.com/izuc/', color: 'bg-[#333]' },
  { icon: <Twitter size={24} />, url: 'https://twitter.com/LanceBlah', color: 'bg-[#1DA1F2]' },
];

const navItems = [
  { name: 'Home', icon: <Home size={24} />, path: '/', color: 'bg-[#0078D7]' },
  { name: 'Resume', icon: <FileText size={24} />, path: '/resume', color: 'bg-[#5133AB]' },
  { name: 'Portfolio', icon: <Monitor size={24} />, path: '/portfolio', color: 'bg-[#107C10]' },
  { name: 'References', icon: <FileCheck size={24} />, path: '/references', color: 'bg-[#E74856]' },
  { name: 'Contact', icon: <Mail size={24} />, path: '/contact', color: 'bg-[#00B7C3]' },
];

const Layout = ({ children }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const videoRef = useRef(null);

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location]);

  const nextVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % profileVideos.length);
  };

  const prevVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex - 1 + profileVideos.length) % profileVideos.length);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#012456] to-[#1E1E1E] text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header */}
        <header className="mb-8">
          <nav className="flex items-center justify-between flex-wrap">
            <Link to="/" className="font-bold text-3xl tracking-tight text-white hover:text-[#0078D7] transition-colors duration-300">
              Lance Edward
            </Link>
            <div className="block lg:hidden">
              <button
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="text-white hover:text-[#0078D7] transition-colors duration-300"
              >
                {mobileMenuOpen ? <X size={28} /> : <Menu size={28} />}
              </button>
            </div>
            <div className={`w-full lg:w-auto ${mobileMenuOpen ? "block" : "hidden"} lg:block mt-4 lg:mt-0`}>
              <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2">
                {navItems.map((item, index) => (
                  <Link
                    key={index}
                    to={item.path}
                    className={`${item.color} p-3 rounded-lg text-white hover:opacity-90 transition-all duration-300 flex items-center transform hover:scale-105 ${location.pathname === item.path ? 'ring-2 ring-white' : ''}`}
                  >
                    <span className="w-6 mr-3">{item.icon}</span>
                    <span className="font-semibold">{item.name}</span>
                  </Link>
                ))}
              </div>
            </div>
          </nav>
        </header>

        {/* Profile Section */}
        <div className="mb-8 bg-gradient-to-r from-[#0078D7] to-[#00B7C3] rounded-lg p-8 shadow-2xl">
          <div className="flex flex-col md:flex-row items-center">
            <div className="relative w-64 h-64 mb-6 md:mb-0 md:mr-8">
              <video
                ref={videoRef}
                src={profileVideos[currentVideoIndex]}
                className="w-full h-full object-cover rounded-lg shadow-md transition-transform duration-500 ease-in-out transform hover:scale-105"
                autoPlay
                loop
                muted
                playsInline
              />
              <button
                onClick={prevVideo}
                className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2 hover:bg-opacity-75 transition-all duration-300"
              >
                <ChevronLeft size={24} />
              </button>
              <button
                onClick={nextVideo}
                className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full p-2 hover:bg-opacity-75 transition-all duration-300"
              >
                <ChevronRight size={24} />
              </button>
            </div>
            <div>
              <h1 className="text-4xl font-bold mb-2 text-white">Lance Edward Baker</h1>
              <p className="text-2xl mb-4 text-yellow-300 font-semibold">Programmer (ABN 49 764 320 344)</p>
              <div className="space-y-3 text-lg">
                <p className="flex items-center">
                  <MapPin size={24} className="mr-3 text-yellow-300" />
                  117/159 Mann St Gosford
                </p>
                <p className="flex items-center">
                  <Phone size={24} className="mr-3 text-yellow-300" />
                  02 800 620 33
                </p>
                <p className="flex items-center">
                  <Globe size={24} className="mr-3 text-yellow-300" />
                  www.website.live
                </p>
                <p className="flex items-center">
                  <Mail size={24} className="mr-3 text-yellow-300" />
                  me@lance.name
                </p>
              </div>
              <div className="mt-6 flex space-x-4">
                {socialLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${link.color} p-3 rounded-full hover:opacity-90 transition-all duration-300 transform hover:scale-110`}
                  >
                    {link.icon}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <main className="mb-8 bg-[#1E1E1E] bg-opacity-80 rounded-lg p-8 shadow-2xl">
          {children}
        </main>

        {/* Footer */}
        <footer className="py-6 text-center border-t border-[#0078D7]">
          <p className="text-lg">&copy; 2024 Lance Edward. All rights reserved.</p>
          <div className="mt-2">
            <Link to="/privacy-policy" className="text-[#00B7C3] hover:text-white transition-colors duration-300 font-semibold">
              Privacy Policy
            </Link>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Layout;