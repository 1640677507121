import React from 'react';

const referenceData = [
  {
    type: 'Work References',
    references: [
      {
        name: 'Stuart Watkins',
        position: '2007-2009 WECSGroup',
        image: '/profiles/stuart.jpg',
        content: "Lance worked for us on our TriCert Assist project. He is an incredibly hard working, forward-thinking programmer, with the ability to look ahead and envisage problems before they occur. No task is considered too large or small for Lance. His dedication to all assigned tasks is commendable. I would highly recommend using him on any projects where programming or I.T assistance is required."
      },
      {
        name: 'Fiona Neville',
        position: '2010-2016 University of Newcastle',
        image: '/profiles/fiona.jpg',
        content: "I have worked in a supervisory capacity with Lance for the last 6 years in the University of Newcastle library at the Ourimbah Campus. In his position as IT Rover, Lance is responsible for assisting students and staff with the wide ranging technical difficulties they may encounter. Lance carries out his duties in a courteous and effective manner. His communication skills are outstanding, and these skills, combined with his IT knowledge, have made him a highly valued member of our workplace. Because of his exceptional IT knowledge and ability to troubleshoot, he is regularly called upon to assist with problems that others have been unable to resolve. Lance is a very capable and independent worker, and has been integral to the success of the team. He is extremely reliable and punctual. He is flexible and prepared to attempt any task that is asked of him. Lance is a truly wonderful member of the library team and I have no hesitation in recommending him.",
        video: "https://www.youtube.com/embed/nPPzdNF4Pew?si=aObWwpPEY21SUYg5"
      },
      {
        name: 'Kalel Johnston',
        position: '2012-2014 Webstuff.biz',
        image: '/profiles/kalel.jpg',
        content: "Lance's output at Webstuff.biz was exemplary, continually being at the front of developing intuitive and in-depth implementations of programming. He had a keen attention to detail - ensuring all projects were polished prior to client testing - and would always look at how current technology could best maximise a project."
      },
      {
        name: 'Andrew Moffett',
        position: '2012-2014 Webstuff.biz',
        image: '/profiles/andrew.jpg',
        content: "Lance is a brilliant and extremely talented programmer. He is the first person I would consult when troubleshooting problems and he always found the solution. He quite easily handled very difficult projects and was always a pleasure to work with."
      },
      {
        name: 'Robert Corse-Scott',
        position: '2015-2016 Website.Live',
        image: '/profiles/robert.jpg',
        content: "Lance is a fantastic programmer with great skills, he is very helpful and passionate about helping businesses achieve their goals. He has worked on several projects for my company in the past which required custom coding for the real estate industry, complex integrations with CRM, invoicing systems such as Xero, shopping carts and more. The added bonus with Lance is that he is a local Australian programmer offering his services very competitively and he listens and works out scope for accurate quoting. I highly recommend Lance for any development work."
      },
      {
        name: 'Stuart Watkins',
        position: '2016-2019 BusinessBasics',
        image: '/profiles/stuart.jpg',
        content: "Our client was using outdated, clunky Sharepoint systems to try and manage complex business processes. Whilst the client knew they needed better systems, they couldn't grasp the complexities of mapping the processes and applying agile project management in order to develop the new, streamlined cloud-based platform they were after. When Lance came on board as the lead developer, his great coding skills were put to use immediately. But he also demonstrated his excellent skills in understanding business processes and how they link together. This meant re-work was kept to a minimum, and features were communicated, developed, and delivered rapidly. Lance's skills resulted in systems that met and exceeded our client's expectations, as he worked with our client for a number of years. I highly recommend Lance for any development projects and will continue to use his services on future projects."
      }
    ]
  }
];

const ReferencePage = () => {
  return (
    <div className="space-y-8">
      <h1 className="text-3xl font-bold mb-6 text-white">References</h1>
      
      {referenceData.map((section, index) => (
        <div key={index} className="mb-8">
          <h2 className="text-2xl font-semibold mb-4 text-blue-300">{section.type}</h2>
          {section.references.map((reference, refIndex) => (
            <div key={refIndex} className="bg-gray-800 rounded-lg p-6 mb-6 shadow-lg">
              <div className="flex flex-col md:flex-row items-start md:items-center mb-4">
                <img src={reference.image} alt={reference.name} className="w-20 h-20 rounded-full object-cover mr-4 mb-4 md:mb-0" />
                <div>
                  <h3 className="text-xl font-semibold text-white">{reference.name}</h3>
                  <p className="text-blue-300">{reference.position}</p>
                </div>
              </div>
              <p className="text-gray-300 mb-4">{reference.content}</p>
              {reference.video && (
                <div className="aspect-w-16 aspect-h-9">
                  <iframe 
                    src={reference.video} 
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen
                    className="w-full h-full"
                  ></iframe>
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ReferencePage;