import React, { useState } from 'react';
import { Send, MapPin, Phone, Mail, Globe } from 'lucide-react';
import axios from 'axios';

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Sending...');
    try {
      const response = await axios.post('/api/contact.php', formData);
      if (response.data.error === 0) {
        setStatus(response.data.info[0].message);
        setFormData({ name: '', email: '', message: '' });
      } else {
        setStatus('Error: ' + response.data.info[0].message);
      }
    } catch (error) {
      setStatus('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="max-w-6xl mx-auto">
      <h2 className="text-3xl font-bold mb-6">Contact Me</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-blue-300">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md bg-white bg-opacity-10 border-transparent focus:border-blue-500 focus:bg-opacity-20 focus:ring-0 text-white"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-blue-300">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md bg-white bg-opacity-10 border-transparent focus:border-blue-500 focus:bg-opacity-20 focus:ring-0 text-white"
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-blue-300">Message</label>
              <textarea
                id="message"
                name="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                required
                className="mt-1 block w-full rounded-md bg-white bg-opacity-10 border-transparent focus:border-blue-500 focus:bg-opacity-20 focus:ring-0 text-white"
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <Send size={20} className="mr-2" /> Send Message
            </button>
          </form>
          {status && <p className="mt-4 text-center text-blue-300">{status}</p>}
        </div>
        <div>
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">Contact Information</h3>
            <p className="flex items-center mb-2"><MapPin className="mr-2" /> 117/159 Mann St Gosford NSW 2250</p>
            <p className="flex items-center mb-2"><Phone className="mr-2" /> 02 800 620 33</p>
            <p className="flex items-center mb-2"><Globe className="mr-2" /> <a href="https://www.lance.name" className="text-blue-300 hover:text-blue-100">www.lance.name</a></p>
            <p className="flex items-center mb-2"><Mail className="mr-2" /> me@lance.name</p>
          </div>
          <div className="h-64 bg-gray-300 rounded-lg overflow-hidden">
            <iframe
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCe167fbLwSYM8CLBoTuTWLZjXXIMNxsLM&q=117/159+Mann+St,+Gosford+NSW+2250,+Australia"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;